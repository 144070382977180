import { Module } from 'vuex'
import { inspirations as inspirationsApi } from '@/api'

export const inspirations: Module<{}, {}> = {
  namespaced: true,

  actions: {
    async listing(_, inspiration) {
      return await inspirationsApi.list(inspiration, {list_all: true})
    },
    async find(_, id) {
      const response = await inspirationsApi.find(id)

      return response.data
    },
    async create(_, data) {
      await inspirationsApi.create(data)
    },
    async update(_, { id, ...data }) {
      await inspirationsApi.update(id, data)
    },
    async delete(_, id) {
      await inspirationsApi.delete(id)
    },
  },
}
